<template>
  <CCard>
    <CCardHeader>
      <strong>{{ title }}</strong>
    </CCardHeader>
    <CCardBody>
      <CForm>
        <CInput
          id="pathology-name"
          description="Nombre de la patología"
          label="Nombre de la patología"
          horizontal
          :value="pathology.pathology_name"
        />
        <CTextarea
            id="pathology-description"
            description="Descripción"
            label="Descripción"
            horizontal
            :value="pathology.pathology_description"
          />
      </CForm>
      <div class="d-flex justify-content-center">
        <CButton
          class="btn btn-secondary" style="margin-right: 1em"
          @click="back()"
        >
          {{backButton}}
        </CButton>
        <CButton
          class="btn btn-success"
          @click="editButton()"
        >
          {{ textButton }}
        </CButton>
      </div>
      <CRow class="justify-content-center">
        <CCol
          md="9"
          class="p-4"
        >
          <CAlert color="danger" dismissible :show.sync="contAlertParams" close-button>
              <strong>AVISO</strong>
              {{errorText}}
              <CProgress
                :max="10"
                :value="contAlertParams"
                height="3px"
                color="danger"
                animated
              />
            </CAlert>
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
import {getPathologiesData, checkUser} from '../../utils/utils';
import axios from "axios";
import "core-js/stable";
import "regenerator-runtime/runtime";
import config from "@/config";

export default {
  name: "EditarPatologia",
  data() {
    return {
      pathology: {},
      id: null,
      title: "Nueva patología",
      backButton: "Volver",
      textButton: "Crear",
      information: false,
      contAlertParams: 0,
      errorText: 'Campos vacios o erroneos'
    };
  },
  created() {
    if(!checkUser()) return;
    this.id = this.$route.params.id;
    if(this.id) this.getPathology();
  },
  methods: {
    /**
     * Función para recibir los datos que se pasaran al componente de la tabla
     */
    receiveData() {
      if (!this.id)
        return;

      this.title = "Editar patologia";
      this.textButton = "Editar";

    },
    back() {
      window.history.back();
    },
    /**
     * Funcion encargada de crear o modificar la patología
     */
    editButton() {
      let name = document.getElementById("pathology-name").value;
      let description = document.getElementById("pathology-description").value;

      if (name == ""){
        this.errorText="El campo Nombre de la patología esta vacio";
        this.showFailureParameters();
        return;
      }
      if (description===""){
        this.errorText="El campo Descripción esta vacio";
        this.showFailureParameters();
        return;
      }
      if (name.length>100){
        this.errorText = "El nombre es demasiado largo.";
        this.showFailureParameters();
        correct = false;
      }
      if (description.length>400){
        this.errorText = "La descripción es demasiado larga.";
        this.showFailureParameters();
        correct = false;
      }

      let token = localStorage.token;

      if (!this.id) {
        axios.post(
            config.BACK_IP +
            config.BACK_IP_PATOLOGIAS,
            {
              name,
              description
            },
            {
              headers: {
                token: token,
              },
            }
          ).then(() => {
            this.$router.push("/pathology");
          }).catch(error => {
            if (error.response) {
              if (error.response.status == 400) {
                this.errorText=error.response.data.message;
                this.showFailureParameters();
              }
              if (error.response.status == 401) {
                window.location = "#/pages/login";
              }
              if (error.response.status == 404) {
                window.location = "#/pages/404";
              }
              if (error.response.status == 409) {
                this.errorText = 'Nombre de la patología repetido.';
                this.showFailureParameters();
              }
              if (error.response.status == 500) {
                window.location = "#/500";
              }
            }
          });

        return;
      }

      axios.patch(config.BACK_IP +config.BACK_IP_PATOLOGIAS + '/' + this.id,
          { name,description},
          {headers: {token: token,},}
        ).then(() => {
          this.$router.push("/pathology");
        }).catch((error) => {
          if (error.response) {
            if (error.response.status == 400) {
              this.errorText=error.response.data.message;
              this.showParametersError = 10;
            }
            if (error.response.status == 401) {
              window.location = "#/pages/login";
            }
            if (error.response.status == 404) {
              window.location = "#/pages/404";
            }
            if (error.response.status == 500) {
              window.location = "#/500";
            }
          }
      });
    },
    showFailureParameters() {
      this.contAlertParams = 10;
    },
    /**
     * Función en la que se obtiene y se guarda la información
     * de las patologias en la variable patologías.
     * 
     * @returns {object[]} Un arreglo con las patologias obtenidas
     */
    getPathology() {

      let data = getPathologiesData();

      data.then((result) => {
        if (!this.id)
        return;

        for (let index = 0; index < result.pathologies.length; index++) {
          if(this.id == result.pathologies[index].id_pathology) {
            this.pathology = result.pathologies[index];
            this.pathology.pathology_name = result.pathologies[index].pathology_name;
            this.pathology.pathology_description = result.pathologies[index].pathology_description;
            break;
          }
        }
      });
      this.receiveData();
    }
  },
};
</script>
<style>
textarea.form-control{
  height: 200px;
}
</style>